<template lang="pug">
  FeatureLayout
    h1.content__title Oops!
    h2.content__subtitle We couldn't find what you were looking for.
    div: Button(:to="{name: backButtonRouteName}", type="outline", prepend-icon="long-arrow-left") Back to {{ backButtonLabel }}

    template(v-slot:feature)
      img.feature__image(src="@/assets/images/pages/page-not-found.jpg")
</template>

<script>
import { mapGetters } from "vuex"
import { copyrightText } from "@/config"
import { FeatureLayout } from "@syntax51/app-kit"

export default {
  metaInfo: {
    title: "Page Not Found",
    bodyAttrs: {
      class: "page--not-found"
    }
  },
  components: {
    FeatureLayout
  },
  data: () => ({
    copyrightText
  }),
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    }),
    backButtonRouteName() {
      let routeName = "auth.login"

      if (this.authenticated) {
        routeName = !this.user.permissions["search.destination"] ? "collections" : "dashboard"
      }

      return routeName
    },
    backButtonLabel() {
      let label = "login"

      if (this.authenticated) {
        label = !this.user.permissions["search.destination"] ? "collections" : "dashboard"
      }

      return label
    }
  }
}
</script>

<style>
.page--not-found {
  .content {
    &__title {
      @apply mb-2 text-5xl;
    }

    &__subtitle {
      @apply mb-10 text-xl;
    }
  }
}
</style>
